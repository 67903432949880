<script setup lang="ts">
interface Props {
  quantity: number
}

const { t } = useI18n()
const { quantity } = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update', payload: { quantity: number }): void
  (e: 'edit'): void
}>()

function handleSubmit({ quantity }: { quantity: number }) {
  emit('update', {
    quantity,
  })
}

defineOptions({
  name: 'CartFormUpdateItem',
})
</script>

<template>
  <div>
    <FormKit
      type="form"
      :actions="false"
      :classes="{ form: 'flex justify-between w-full' }"
      @submit="handleSubmit"
    >
      <KButton
        :text="t('btn.edit')"
        :theme="BUTTON_THEME.SECONDARY"
        class="inline-flex"
        @click="emit('edit')"
      />

      <FormKit
        type="number"
        name="quantity"
        :deletable="true"
        :step="1"
        :min="1"
        :delay="300"
        :value="quantity"
        @input="(value: string) => handleSubmit({ quantity: Number(value) })"
      />
    </FormKit>
  </div>
</template>

<i18n>
de:
  btn:
    edit: "Bearbeiten"
es:
  btn:
    edit: "Editar"
</i18n>
