<script setup lang="ts">
import type { Price, VoucherProduct } from '#gql/default'
import { BUTTON_THEME } from '@kh-ui/constants/button'

const { t, n } = useI18n()
const { cart } = await useCart()
const { icons } = useDesign()
const isMinimised = ref(true)

const items = computed(() => {
  return cart.value?.voucherProducts.map(
    ({
      price,
      quantity,
      voucherProduct,
    }: {
      price: Price
      quantity: number
      voucherProduct: VoucherProduct
    }) => ({
      name: formatQuantityAndName(quantity, voucherProduct.name),
      price: n(price.total, 'currency'),
    }),
  )
})

defineOptions({
  name: 'CartOverview',
})
</script>

<template>
  <div
    class="dark:bg-neutral-dark-2 bg-neutral-light-1 text:dark !space-y-2 rounded-md px-4 py-2"
  >
    <KButton
      :theme="BUTTON_THEME.TRANSPARENT"
      :text="t('btn.orderOverview')"
      :suffix-icon="icons.CHEVRON_DOWN"
      class="w-full justify-between space-x-0 !pl-0 text-lg font-bold data-[suffix]:!pr-0 [&>svg]:transition-transform md:[&>svg]:hidden"
      :class="{
        '[&>svg]:rotate-180 ': !isMinimised,
      }"
      @click="isMinimised = !isMinimised"
    />

    <div class="dark:divide-neutral-dark-1 divide-neutral-light-1 divide-y">
      <div class="md:block" :class="{ hidden: isMinimised }">
        <div
          v-for="{ name, price } in items"
          :key="name"
          class="flex justify-between gap-2 py-2"
        >
          <div class="truncate" v-text="name" />
          <div v-text="price" />
        </div>
        <div class="flex justify-between py-2 font-bold">
          <div v-text="t('subTotalPrice')" />
          <div v-text="n(cart?.price.total, 'currency')" />
        </div>
      </div>

      <div class="flex flex-wrap justify-between py-2">
        <div class="text-lg font-bold" v-text="t('totalPrice')" />
        <div
          class="text-lg font-bold"
          v-text="n(cart?.price.total, 'currency')"
        />
      </div>
    </div>
  </div>
</template>

<i18n>
de:
  subTotalPrice: "Zwischensumme"
  totalPrice: "Gesamt"
  btn:
    orderOverview: "Bestellübersicht"
es:
  subTotalPrice: "Subtotal"
  totalPrice: "Total"
  btn:
    orderOverview: "Resumen de la orden"
</i18n>
