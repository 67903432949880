<script setup lang="ts">
import type { Cinema } from '#gql/default'

interface Props {
  cinema: Cinema
}

const { cinema } = defineProps<Props>()

const { t } = useI18n()

const { ct, fetchCinemaStrings } = useCinemaStrings()
await fetchCinemaStrings({
  keys: ['PANEL_SHOP_INTRO'],
  cinemaId: cinema.id,
})

usePageMetas({
  title: `${cinema.name} | ${t('shop')}`,
  description: t('description', { cinemaName: cinema.name }),
  ...cinema.meta,
})

useLegacyWidget({
  iframeName: 'kinoheld-widget',
  cinemaPath: `kino-${cinema.city.urlSlug}/${cinema.urlSlug}/shop`,
  floatingCart: false,
  hideSearch: true,
})

const { $gtm } = useNuxtApp()

onMounted(() => {
  $gtm.trackPageView()
})

defineOptions({
  name: 'PageCinemaCitySlugCinemaSlugShop',
})
</script>

<template>
  <PageGridColumn class="widget:pt-0 py-8">
    <CinemaBanner :cinema page="SHOP" class="mb-8" />
    <div class="prose dark:prose-invert" v-html="ct('PANEL_SHOP_INTRO')" />
    <div id="kinoheld-eframe" />
  </PageGridColumn>
</template>

<i18n>
de:
  shop: "Shop"
  description: "Jetzt bei kinoheld Merchandise für das Kino {cinemaName} schnell und bequem online kaufen."
</i18n>
